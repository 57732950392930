<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(storeRepInfoTempId)">
        <div class="form-group row mb-4 mt-4">
          <div class="col-xxl-5 col-xl-5 col-lg-6 col-md-12">
            <validation-provider
              name="agent id"
              :rules="{ required: fields[0].required }"
              v-slot="validationContext"
              v-if="fields[0]"
            >
              <label>
                Representative ID
                <span
                  class="required-indc"
                  v-if="fields[0] && fields[0].required"
                >
                  *
                </span>
              </label>
              <div class="agent-input">
                <input
                  type="text"
                  class="agent-field"
                  :class="
                    (apiErrors && apiErrors[fields[0].field_name]) ||
                    validationContext.errors[0]
                      ? 'error-input'
                      : ''
                  "
                  v-if="fields[0] && fields[0].field_name"
                  placeholder="eg: NENY9999"
                  :disabled="fields[0] && fields[0].readOnly"
                  v-model="form[fields[0].field_name]"
                />
                <error-text
                  v-if="
                    (apiErrors && apiErrors[fields[0].field_name]) ||
                    validationContext.errors[0]
                  "
                  :errors="
                    apiErrors
                      ? apiErrors
                      : validationContext.errors
                      ? validationContext.errors
                      : ''
                  "
                  :field="fields[0].field_name"
                />
                <span class="key-icon">
                  <img
                    src="@/assets/images/registration/key.svg"
                    class="img-fluid"
                  />
                </span>
              </div>
            </validation-provider>
          </div>
        </div>
        <div class="action-btn action-next-btn text-end float-right">
          <button class="button-blue" type="submit">Next</button>
        </div>
      </form>
    </ValidationObserver>
    <div>
      <p v-if="this.domains.length > 0" class="assigned-para">
        Assigned Platforms:
      </p>
      <ul>
        <b-badge
          class="domain-badge"
          v-for="(domain, index) in domains"
          :key="index"
          variant="success"
        >
          {{ domain.name }}
        </b-badge>
      </ul>
    </div>
  </div>
</template>
<script>
import axios from '@/utils/regAxios'

export default {
  name: 'BrokerForm',
  props: {
    fields: {
      required: true,
    },
    form: {
      required: true,
    },
  },
  data: () => ({
    apiErrors: null,
    domainIds: null,
    domains: [],
    repCode: null,
  }),

  methods: {
    getDomainIds() {
      axios
        .get(`/get-enrollment-site?rep_code=${this.repCode}`)
        .then((response) => {
          if (Array.isArray(this.domainIds)) {
            this.domains = response.data.data.filter((domain) => {
              return this.domainIds.includes(domain.id)
            })
          }
          this.$store.commit('setRegFormUrl', 'representative-info')
          this.$store.commit('setRegTitle', 'Rep Information')
          this.$store.commit('setRegTab', 'broker-info')
          this.$store.commit('setDomainIds', this.domainIds)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async storeRepInfoTempId() {
      let regTempId = this.$store.getters.regTempId

      /**
        check agent code
        if same as from and fetched from api
      */

      let [fieldName, fieldValue] = [
        this.fields[0].field_name,
        this.fields[0].value,
      ]
      let formData = this.form
      if (this.form[fieldName] == fieldValue && regTempId) {
        this.form.registration_id = regTempId
        formData = (({ registration_id, agent_ga, group_census_id }) => ({
          registration_id,
          agent_ga,
          group_census_id
        }))(formData)
      } else {
        formData = (({ agent_ga, group_census_id }) => ({
          agent_ga,
          group_census_id
        }))(formData)
      }
      const url = 'register/representative-info'
      let loader = this.$loading.show()
      let domainIds = this.$store.getters.domainIds
      try {
        let res = await axios.post(url, formData)
        if (res.data.statusCode == 200) {
          this.$store.commit('setRegTempId', res.data.data.registration_id)
          this.$emit('onSuccess')
          this.$emit('onSetInfo', this.form[this.fields[0].field_name])

          let regTempId = res.data.data.registration_id
          axios
            .post('register/personalized-site', {
              registration_id: regTempId,
              id: domainIds,
            })
            .then((response) => {
              console.log('Response data', response)
            })
            .catch((err) => {
              console.log(err)
            })
        }
      } catch (err) {
        if (err.response.data.statusCode == 422) {
          this.apiErrors = err.response.data.errors
          setTimeout(() => {
            this.apiErrors = null
          }, 5000)
        } else {
          this.$notify(err.response.data.message, 'Error', 'error')
        }
        this.$store.commit('setRegTempId', null)
      } finally {
        loader.hide()
      }
    },
  },
  created() {
    const { domainIds, repCode } = this.$route.params
    let decodedDomainIds = atob(domainIds)
    this.domainIds = decodedDomainIds.split(',').map((id) => Number(id.trim()))
    this.repCode = repCode

    this.getDomainIds()
  },
}
</script>

<style>
.assigned-para {
  color: #5e5e5e;
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: 500;
}
.domain-badge {
  background: #03c3ec;
  font-size: 13px;
  padding: 5px;
  color: #fff;
  font-weight: 500;
  border-radius: 60px;
  margin: 5px;
  padding: 0.7rem;
}
.action-next-btn {
  padding-top: 10rem;
}
@media (max-width: 1500px) {
  .assigned-para {
    font-size: 13px;
  }
  .domain-badge {
    font-size: 11px;
  }
}
</style>
