<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="form-group mb-4">
          <reg-input-text
            label='Type your full name which will be your "signature" to verify application terms and agreement'
            labelName="fullname"
            :form="form"
            :field="fields[0]"
            placeholder="Enter Full Name"
            :api-errors="apiErrors"
            formGroupClass=""
            :rules="{ required: fields[0].required }"
          />
        </div>

        <div class="form-group row">
          <reg-input-date-picker
            label="Signature Date"
            :form="form"
            :field="fields[1]"
            :api-errors="apiErrors"
            date-format="MM/DD/YYYY"
            :rules="{ required: fields[1].required }"
          />
          <reg-input-text
            label="Verification Code"
            :form="form"
            :field="fields[2]"
            placeholder="Enter Full Name"
            :api-errors="apiErrors"
            :rules="{ required: fields[2].required }"
            input-class="verification-disabled"
          />
        </div>
        <div class="form-group">
          <reg-input-text
            label="Verify Code"
            :form="form"
            :field="fields[3]"
            placeholder="Enter Verification Code Here"
            :api-errors="apiErrors"
            formGroupClass=""
            :rules="{ required: fields[3].required }"
          />
        </div>
        <div class="action-btn split-action mt-5">
          <button class="button-black" @click="backHandler">Back</button>
          <div class="action-next">
            <button class="button-blue reset-btn" @click.prevent="resetHandler">
              Reset
            </button>
            <button class="button-green" @click.prevent="saveAndContinue">
              Save & Continue Later
            </button>
            <button class="button-green submit-btn" type="submit">
              Submit
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import axios from "@/utils/regAxios";
import RegInputDatePicker from "../utils/RegInputDatePicker.vue";
import Screenshot from "@/utils/screenshot"

export default {
  name: "SignatureForm",
  components: { RegInputDatePicker },
  props: {
    fields: {
      required: true,
    },
    form: {
      required: true,
    },
  },
  data: () => ({
    apiErrors: null,
  }),
  methods: {
    saveAndContinue() {
      this.$emit("onOpenSaveAndContinue", this.form);
    },
    resetHandler() {
      this.$confirm({
        title: "Reset Form",
        message: `Do you want to reset this form?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$emit("onResetForm");
          }
        },
      });
    },
    async submit() {
      this.form.registration_id = this.$store.getters.regTempId;
      let loader = this.$loading.show();
      let formData = this.form;
      Screenshot.captureScreenShot(Screenshot.regScreenShotSelector).then((res)=>{
        if(res){
          formData['screenshot'] = res;
        }
      }).finally(()=>{
        loader.hide();
        const url = "register/verification-info";
        this.save(url , formData);
      });

    },
    async save(url , payload){
      let loader = this.$loading.show();
      try {
        let res = await axios.post(url, payload);
        if (res.data.statusCode == 200) {
          this.$emit("onSuccess");
        }
      } catch (err) {
        if (err.response.data.statusCode == 422) {
          this.apiErrors = err.response.data.errors;
          setTimeout(() => {
            this.apiErrors = null;
          }, 5000);
        } else {
          this.$notify(err.response.data.message, "Error", "error");
        }
      } finally {
        loader.hide();
      }
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    onBegin() {
      this.$refs.signaturePad.resizeCanvas();
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        let field = this.fields[2].field_name;
        this.form[field] = data;
      }
    },
    backHandler() {
      this.$emit("onBackHandler");
    },
  },
};
</script>
