<template>
  <b-modal id="routing_number_info" centered hide-footer hide-header size="lg">
    <img
      @click="$bvModal.hide('routing_number_info')"
      class="close_cross"
      src="@/assets/images/cross.svg"
      alt="cross"
    />
    <div class="col-md-12 routing-img">
    <img src="@/assets/images/routing_number.jpg" class="image image-responsive">
    </div>
  </b-modal>
</template>
<script>
export default {
    name:"RoutingNumberInfo"
};
</script>
<style scoped>
.routing-img img{
  width: 100%;
}
</style>
