<template>
  <validation-provider
    :name="labelName.toLowerCase()"
    :rules="rules"
    v-slot="validationContext"
  >
    <div :class="formGroupClass">
      <label v-if="label">
        {{ label }}
        <span class="required-indc" v-if="field && field.required"> * </span>
      </label>
      <div :class="inputClass" v-for="option in options" :key="option.label">
        <label class="custom-radio"
          >{{ option.label }}
          <input
            :id="field.field_name"
            type="radio"
            :value="option.value"
            :disabled="field && field.readOnly"
            v-model="form[field.field_name]"
            @input="inputHandler"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="col-md-12">
        <error-text
          v-if="
            (apiErrors && apiErrors[field.field_name]) ||
            validationContext.errors[0]
          "
          :errors="
            apiErrors
              ? apiErrors
              : validationContext.errors
              ? validationContext.errors
              : ''
          "
        :field="field.field_name"
        />
      </div>
    </div>
  </validation-provider>
</template>
<script>
export default {
  name: "RegInputRadio",
  props: {
    apiErrors: {
      required: false,
    },
    form: {
      required: true,
    },
    field: {
      required: true,
    },
    label: {
      required: true,
    },
    formGroupClass: {
      required: false,
      default: "form-group row",
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    inputClass: {
      required: false,
      default: "col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-4",
    },
    options: {
      required: true,
    },
    labelName: {
      type: String,
      required: true,
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      if (this.rules) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
    inputHandler() {
      this.$emit("inputHandler");
    },
  },
};
</script>