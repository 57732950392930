<template>
  <div class="regInfo-wrap text-center">
    <div class="status-idc">
      <ul>
        <li
          v-for="i in tabNumbers"
          :key="i"
          :class="i === currentTab ? 'active' : ''"
        >
          {{ i }}
        </li>
      </ul>
      <span class="seperator"></span>
    </div>
    <div class="regInfo-icon">
      <img :src="regInfoIcon" alt="" class="img-fluid" />
    </div>
    <h2>{{ title }}</h2>
    <p>
      Fill out the form on the left <br />
      You can always edit the data <br />
      in the setting menu.
    </p>
    <span class="seperator"></span>
    <ul class="mt-2" v-if="info">
      <li>{{ info.phone }}</li>
      <li>{{ info.email }}</li>
    </ul>
    <div class="sidebarHome">
      <button class="button-black" @click="$router.push({ name: 'GroupDashboard' })">
        Home
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "RegistrationSidebar",
   props:{
    info:{
      required:true,
    }
  },
  data: () => ({
    currentTab: 1,
    icon: "broker.svg",
  }),
  computed: {
    ...mapGetters({
      formUrl: "regFormUrl",
      tab: "regTab",
      title: "regTitle",
      regTempId: "regTempId",
    }),
    regInfoIcon() {
      return require(`@/assets/images/registration/${this.icon}`);
    },
    tabNumbers() {
      if (this.currentTab <= 4) {
        return [1, 2, 3, 4];
      } else {
        let data = [];
        for (let i = 1; i <= this.currentTab; i++) {
          data.push(i);
        }
        return data.slice(-4);
      }
    },
  },
  methods: {
    getData() {
      switch (this.tab) {
        case "broker-info":
          [this.currentTab, this.icon] = [1, "broker.svg"];
          break;
        case "group-info":
          [this.currentTab, this.icon] = [2, "group.svg"];
          break;
        case "contact-info":
          [this.currentTab, this.icon] = [3, "contact.svg"];
          break;
        case "login-info":
          [this.currentTab, this.icon] = [4, "user-data.svg"];
          break;
        case "address-info":
          [this.currentTab, this.icon] = [5, "address.svg"];
          break;
        case "billing-contact-info":
          [this.currentTab, this.icon] = [6, "contact.svg"];
          break;
        case "payment-info":
          [this.currentTab, this.icon] = [7, "credit-card.svg"];
          break;
        case "group-contribution":
          [this.currentTab, this.icon] = [8, "puzzle.svg"];
          break;
        case "verification-info":
          [this.currentTab, this.icon] = [9, "verification.svg"];
          break;
        default:
          [this.currentTab, this.icon] = [1, "broker.svg"];
      }
    },
  },
  beforeUpdate() {
    this.getData();
  },
  created() {
    this.getData();
  },
};
</script>