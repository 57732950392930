<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="form-group row">
          <reg-input-text
            v-if="fields[0]"
            label="First Name"
            :form="form"
            :field="fields[0]"
            placeholder="Enter First Name"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[1].required }"
          />
          <reg-input-text
            v-if="fields[1]"
            label="Last Name"
            :form="form"
            :field="fields[1]"
            placeholder="Enter Last Name"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[2].required }"
          />
        </div>

        <div class="form-group">
          <reg-input-text
            v-if="fields[2]"
            label="Contact Title"
            :form="form"
            :field="fields[2]"
            placeholder="Enter Contact Title"
            :api-errors="apiErrors"
            formGroupClass="mb-4"
            :rules="{ required: fields[2].required }"
          />
        </div>

        <div class="form-group row">
          <reg-input-text
            v-if="fields[3]"
            label="Mobile Number"
            type="number"
            :form="form"
            :field="fields[3]"
            placeholder="Enter Mobile Number"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[3].required, digits: 10 }"
          />
          <reg-input-text
            v-if="fields[4]"
            label="FAX Number"
            type="number"
            :form="form"
            :field="fields[4]"
            placeholder="Enter FAX Number"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[4].required, digits: 10 }"
          />
        </div>

        <div class="action-btn split-action mt-3">
          <button class="button-black" @click="backHandler">Back</button>
          <div class="action-next">
            <button class="button-green" @click.prevent="saveAndContinue">
              Save & Continue Later
            </button>
            <button class="button-blue" type="submit">Next</button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import axios from "@/utils/regAxios";
import Screenshot from "@/utils/screenshot"

export default {
  name: "ContactForm",
  props: {
    fields: {
      required: true,
    },
    form: {
      required: true,
    },
  },
  data: () => ({
    apiErrors: null,
  }),
  methods: {
    saveAndContinue() {
      this.$emit("onOpenSaveAndContinue", this.form);
    },
    backHandler() {
      this.$emit("onBackHandler");
    },
    async submit() {
      this.form.registration_id = this.$store.getters.regTempId;
      let loader = this.$loading.show();
      let formData = this.form;
      Screenshot.captureScreenShot(Screenshot.regScreenShotSelector).then((res)=>{
        if(res){
          formData['screenshot'] = res;
        }
      }).finally(()=>{
        loader.hide();
        const url = "register/contact-info";
        this.save(url , formData);
      });
    },
    async save(url , payload){
      let loader = this.$loading.show();
      try {
        let res = await axios.post(url,payload);
        if (res.data.statusCode == 200) {
          this.$emit("onSuccess");
        }
      } catch (err) {
        if (err.response.data.statusCode == 422) {
          this.apiErrors = err.response.data.errors;
          setTimeout(() => {
            this.apiErrors = null;
          }, 5000);
        } else {
          this.$notify(err.response.data.message,"Error",  "error");
        }
      } finally {
        loader.hide();
      }
    }
  },
  created() {
    if (!this.$store.getters.regTempId) {
      this.backHandler();
    }
  },
};
</script>
