var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.storeRepInfoTempId)}}},[_c('div',{staticClass:"form-group row mb-4 mt-4"},[_c('div',{staticClass:"col-xxl-5 col-xl-5 col-lg-6 col-md-12"},[(_vm.fields[0])?_c('validation-provider',{attrs:{"name":"agent id","rules":{ required: _vm.fields[0].required }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',[_vm._v(" Representative ID "),(_vm.fields[0] && _vm.fields[0].required)?_c('span',{staticClass:"required-indc"},[_vm._v(" * ")]):_vm._e()]),_c('div',{staticClass:"agent-input"},[(_vm.fields[0] && _vm.fields[0].field_name)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[_vm.fields[0].field_name]),expression:"form[fields[0].field_name]"}],staticClass:"agent-field",class:(_vm.apiErrors && _vm.apiErrors[_vm.fields[0].field_name]) ||
                  validationContext.errors[0]
                    ? 'error-input'
                    : '',attrs:{"type":"text","placeholder":"eg: NENY9999","disabled":_vm.fields[0] && _vm.fields[0].readOnly},domProps:{"value":(_vm.form[_vm.fields[0].field_name])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, _vm.fields[0].field_name, $event.target.value)}}}):_vm._e(),(
                  (_vm.apiErrors && _vm.apiErrors[_vm.fields[0].field_name]) ||
                  validationContext.errors[0]
                )?_c('error-text',{attrs:{"errors":_vm.apiErrors
                    ? _vm.apiErrors
                    : validationContext.errors
                    ? validationContext.errors
                    : '',"field":_vm.fields[0].field_name}}):_vm._e(),_c('span',{staticClass:"key-icon"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/registration/key.svg")}})])],1)]}}],null,true)}):_vm._e()],1)]),_c('div',{staticClass:"action-btn action-next-btn text-end float-right"},[_c('button',{staticClass:"button-blue",attrs:{"type":"submit"}},[_vm._v("Next")])])])]}}])}),_c('div',[(this.domains.length > 0)?_c('p',{staticClass:"assigned-para"},[_vm._v(" Assigned Platforms: ")]):_vm._e(),_c('ul',_vm._l((_vm.domains),function(domain,index){return _c('b-badge',{key:index,staticClass:"domain-badge",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(domain.name)+" ")])}),1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }