<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <reg-input-radio
          v-if="fields[0]"
          label=""
          labelName="group contribution"
          :form="form"
          :field="fields[0]"
          :api-errors="apiErrors"
          :options="groupContributionOptions"
          :rules="{ required: fields[0].required }"
          @inputHandler="handleGroupContribution"
        />

        <div v-if="fields && fields[0] && form[fields[0].field_name] == '1'">
          <h3>Group Defined Contribution Type</h3>
          <validation-provider
            name="contribution_type"
            :rules="{ required: fields[1].required }"
            v-slot="validationContext"
            v-if="fields[1]"
          >
            <div>
              <label class="custom-radio mb-4"
                >Flat Dollar Amount
                <input
                  :id="fields[1].field_name"
                  type="radio"
                  :value="contributionTypes[0].value"
                  :disabled="fields[1] && fields[1].readOnly"
                  v-model="form[fields[1].field_name]"
                />
                <span class="checkmark"></span>
              </label>
              <div
                class="form-group row"
                v-if="form[fields[1].field_name] == 'f'"
              >
                <reg-input-text
                  v-if="fields[2]"
                  :labelName="fields[2].field_name"
                  type="number"
                  :form="form"
                  :field="fields[2]"
                  placeholder="Enter Amount"
                  :api-errors="apiErrors"
                  formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
                  :rules="{ required: fields[2].required }"
                />
              </div>

              <label class="custom-radio mb-4"
                >Percentage
                <input
                  :id="fields[1].field_name"
                  type="radio"
                  :value="contributionTypes[1].value"
                  :disabled="fields[1] && fields[1].readOnly"
                  v-model="form[fields[1].field_name]"
                />
                <span class="checkmark"></span>
              </label>
              <div class="col-md-12">
                <error-text
                  v-if="
                    (apiErrors && apiErrors[fields[1].field_name]) ||
                    validationContext.errors[0]
                  "
                  :errors="
                    apiErrors
                      ? apiErrors
                      : validationContext.errors
                      ? validationContext.errors
                      : ''
                  "
                  :field="fields[1].field_name"
                />
              </div>
            </div>
          </validation-provider>

          <div class="form-group row" v-if="form[fields[1].field_name] == 'p'">
            <reg-input-text
              v-if="fields[3]"
              label="Member"
              type="number"
              :form="form"
              :field="fields[3]"
              placeholder="Enter Percent"
              :api-errors="apiErrors"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-4"
              :rules="{ required: fields[3].required }"
            />

            <reg-input-text
              v-if="fields[4]"
              label="Member Spouse"
              type="number"
              :form="form"
              :field="fields[4]"
              placeholder="Enter Percent"
              :api-errors="apiErrors"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-4"
              :rules="{ required: fields[4].required }"
            />
            <reg-input-text
              v-if="fields[5]"
              label="Member + Child(ren)"
              :labelName="fields[5].field_name"
              type="number"
              :form="form"
              :field="fields[5]"
              placeholder="Enter Percent"
              :api-errors="apiErrors"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-4"
              :rules="{ required: fields[5].required }"
            />
            <reg-input-text
              v-if="fields[6]"
              label="Family"
              :labelName="fields[6].field_name"
              type="number"
              :form="form"
              :field="fields[6]"
              placeholder="Enter Percent"
              :api-errors="apiErrors"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-4"
              :rules="{ required: fields[6].required }"
            />
          </div>
        </div>
        <div class="action-btn split-action mt-3">
          <button class="button-black" @click="backHandler">Back</button>

          <div class="action-next">
            <button class="button-green" @click.prevent="saveAndContinue">
              Save & Continue Later
            </button>
            <button class="button-blue" type="submit">Next</button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import axios from "@/utils/regAxios";
import RegInputRadio from "../utils/RegInputRadio.vue";
import ErrorText from "../../../components/ui/ErrorText.vue";
import { parse } from "path";
import Screenshot from "@/utils/screenshot";

export default {
  components: { RegInputRadio, ErrorText },
  name: "ContributionForm",
  props: {
    fields: {
      required: true,
    },
    form: {
      required: true,
    },
  },
  data: () => ({
    apiErrors: null,
    components: { RegInputRadio },
    groupContributionOptions: [
      { label: "Yes", value: "1" },
      { label: "No", value: "0" },
    ],
    contributionTypes: [
      { label: "Flat Dollar Amount", value: "f" },
      { label: "Percentage", value: "p" },
    ],
  }),
  methods: {
    handleGroupContribution(contribution) {
      if (contribution == 1) {
        this.$store.commit("setRegGroupContribution", true);
      } else {
        this.$store.commit("setRegGroupContribution", false);
      }
    },
    saveAndContinue() {
      this.$emit("onOpenSaveAndContinue", this.form);
    },
    backHandler() {
      this.$emit("onBackHandler");
    },
    async submit() {
      this.form.registration_id = this.$store.getters.regTempId;
      let loader = this.$loading.show();
      let formData = this.form;
      Screenshot.captureScreenShot(Screenshot.regScreenShotSelector).then((res)=>{
        if(res){
          formData['screenshot'] = res;
        }
      }).finally(()=>{
        loader.hide();
        const url = "register/group-contribution";
        this.save(url , formData);
      });
    },
    async save(url , payload){
      let loader = this.$loading.show();
      try {
        let res = await axios.post(url, payload);
        if (res.data.statusCode == 200) {
          this.$emit("onSuccess");
        }
      } catch (err) {
        if (err.response.data.statusCode == 422) {
          this.apiErrors = err.response.data.errors;
          setTimeout(() => {
            this.apiErrors = null;
          }, 5000);
        } else {
          this.$notify(err.response.data.message, "Error", "error");
        }
      } finally {
        loader.hide();
      }
    }
  },
  created() {
    if (!this.$store.getters.regTempId) {
      this.backHandler();
    }
  },
  watch: {
    "form.group_contribution": {
      handler: function (val) {
        if (val == 1) {
          this.$store.commit("setRegGroupContribution", true);
        } else {
          this.$store.commit("setRegGroupContribution", false);
        }
      },
      deep: true,
    },
  },
};
</script>
