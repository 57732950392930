<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="form-group row">
        <reg-input-text
          v-if="fields[0]"
          label="Group Name"
          :form="form"
          :field="fields[0]"
          placeholder="Enter Group Name"
          :api-errors="apiErrors"
          :rules="{ required: fields[0].required }"
        />
        <reg-input-text
          v-if="fields[1]"
          label="Estimate Number of Members"
          :form="form"
          :field="fields[1]"
          placeholder="eg: 10"
          :api-errors="apiErrors"
          :rules="checkValidation()"
        />
      </div>

      <div class="form-group row">
        <reg-input-date-picker
          v-if="fields[2]"
          label="Desired Start Date"
          :form="form"
          :field="fields[2]"
          :api-errors="apiErrors"
          placeholder="MM/DD/YYYY"
          date-format="MM/DD/YYYY"
          :rules="{ required: fields[2].required }"
        />

        <reg-input-select
          v-if="fields[3]"
          label="Group Type"
          :form="form"
          :field="fields[3]"
          :options="groupTypes.map((type) => type.value)"
          :custom-label="(opt) => groupTypes.find((x) => x.value == opt).name"
          placeholder="Select Group Type"
          :api-errors="apiErrors"
          :rules="{ required: fields[3].required }"
        />
      </div>
      <div class="form-group">
        <reg-input-text
          v-if="fields[4]"
          label="Federal TAX ID Number(TIN/EIN)"
          semi-label="Social Security If Partnership"
          type="number"
          :form="form"
          :field="fields[4]"
          placeholder="Enter Federal TAX ID Number(TIN/EIN)"
          :api-errors="apiErrors"
          :rules="{ required: fields[1].required,digits:9 }"
          form-group-class="mb-4"
        />
      </div>

      <div class="form-group">
        <reg-input-select
          v-if="fields[5]"
          label="Group Industry"
          :form="form"
          :field="fields[5]"
          :options="groupIndustries.map((type) => type.value)"
          :custom-label="
            (opt) => {
            const industry = groupIndustries.find((x) => x.value == opt);
            return industry ? industry.name : `${fields[5].value}`;
           }
          "
          placeholder="Select Industry"
          :api-errors="apiErrors"
          :rules="{ required: fields[5].required }"
          form-group-class="mb-4"
        />
      </div>
      <div class="action-btn split-action mt-3">
        <button class="button-black" @click="backHandler">Back</button>
        <div class="action-next">
          <button class="button-green" @click.prevent="saveAndContinue">
            Save & Continue Later
          </button>
          <button class="button-blue" type="submit">Next</button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import axios from "@/utils/regAxios";
import RegInputDatePicker from "../utils/RegInputDatePicker.vue";
import Screenshot from "@/utils/screenshot"

export default {
  components: { RegInputDatePicker },
  name: "GroupInfoForm",
  props: {
    fields: {
      required: true,
    },
    form: {
      required: true,
    },
    groupTypes: {
      required: true,
    },
    groupIndustries: {
      required: true,
    },
  },
  computed: {
    groupCensusId () {
      return this.$store.getters.groupCensusId
    }
  },
  data: () => ({
    apiErrors: null,
  }),
  methods: {
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    saveAndContinue() {
      this.$emit("onOpenSaveAndContinue", this.form);
    },
    backHandler() {
      this.$emit("onBackHandler");
    },
    async submit() {
      this.form.registration_id = this.$store.getters.regTempId;
      let loader = this.$loading.show();
      let formData = this.form;
      Screenshot.captureScreenShot(Screenshot.regScreenShotSelector).then((res)=>{
        if(res){
          formData['screenshot'] = res;
        }
      }).finally(()=>{
        loader.hide();
        const url = "register/group-info";
        this.save(url , formData);
      });
    },
    async save(url , payload){
      let loader = this.$loading.show();
      try {
        let res = await axios.post(url, payload);
        this.$store.commit("setRegGroupType",this.form.group_type)
        if (res.data.statusCode == 200) {
          this.$emit("onSuccess");
        }
      } catch (err) {
        if (err.response.data.statusCode == 422) {
          this.apiErrors = err.response.data.errors;
          setTimeout(() => {
            this.apiErrors = null;
          }, 5000);
        } else {
          this.$notify(err.response.data.message,"Error",  "error");
        }
      } finally {
        loader.hide();
      }
    },
    checkValidation () {
      return this.groupCensusId ? `required|integer|min_value:10` : `required|integer`
    }
  },
  created() {
    if (!this.$store.getters.regTempId) {
      this.backHandler();
    }
  },
};
</script>
