<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="form-group row">
          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-4">
            <label>
              Payment Method
              <span
                class="required-indc"
                v-if="fields[0] && fields[0].required"
              >
                *
              </span>
            </label>

            <label class="custom-radio"
              >EFT/ACH
              <input
                type="radio"
                name="radio"
                v-if="fields[0] && fields[0].field_name"
                :disabled="fields[0] && fields[0].readOnly"
                v-model="form[fields[0].field_name]"
                :checked="true"
                value="ach"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="form-group row" v-if="regGroupType == 'employer'">
          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4">
          <strong>You are paying for employee.</strong>
          </div>
        </div>
        <div class="form-group row">
          <reg-input-text
            v-if="fields[3]"
            label="Routing Number"
            type="number"
            :form="form"
            :field="fields[3]"
            placeholder="Enter Routing Number"
            :api-errors="apiErrors"
            @inputHandler="updateRoutingNumber"
            :rules="{ required: fields[3].required, digits: 9 }"
          >
            <template v-slot:shortMessage>
              <span class="short-msg">
                What is Routing Number?
                <a
                  href="javascript:void(0);"
                  @click="$bvModal.show('routing_number_info')"
                >
                  Click Here
                </a>
              </span>
            </template>
          </reg-input-text>

          <reg-input-text
            v-if="fields[4]"
            label="Bank Name"
            :form="form"
            :field="fields[4]"
            placeholder="Enter Bank Name"
            :api-errors="apiErrors"
            :rules="{ required: fields[4].required }"
          />
        </div>

        <div class="form-group row">
          <reg-input-text
            v-if="fields[2]"
            label="Account Number"
            :form="form"
            :field="fields[2]"
            placeholder="Enter Account Number"
            :api-errors="apiErrors"
            :rules="{ required: true, min:4, max:17 }"
          />

          <reg-input-text
            v-if="fields[1]"
            label="Name on Bank Account"
            labelName="account name"
            :form="form"
            :field="fields[1]"
            placeholder="Enter Account Name"
            :api-errors="apiErrors"
            :rules="{ required: fields[1].required }"
          />
        </div>
        <div class="form-group row">
          <reg-input-select
            v-if="fields[5]"
            label="Account Type"
            :form="form"
            :field="fields[5]"
            :options="accountTypes.map((type) => type.value)"
            :custom-label="
              (opt) => accountTypes.find((x) => x.value == opt).name
            "
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            placeholder="Choose Account Type"
            :api-errors="apiErrors"
            :rules="{ required: fields[5].required }"
          />

          <reg-input-select
            v-if="fields[6]"
            label="Account Holder Type"
            :form="form"
            :field="fields[6]"
            :options="accountHolderTypes.map((type) => type.value)"
            :custom-label="
              (opt) => accountHolderTypes.find((x) => x.value == opt).name
            "
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            placeholder="Choose Account Holder Type"
            :api-errors="apiErrors"
            :rules="{ required: fields[6].required }"
          />
        </div>
        <div class="action-btn split-action mt-5">
          <button class="button-black" @click="backHandler">Back</button>
          <div class="action-next">
            <button class="button-green" @click.prevent="saveAndContinue">
              Save & Continue Later
            </button>
            <button class="button-blue" type="submit">Next</button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <routing-number-info />
  </div>
</template>
<script>
import newAxios from "axios";
import { mapGetters } from "vuex";
import axios from "@/utils/regAxios";
import RoutingNumberInfo from "../RoutingNumberInfo.vue";
import Screenshot from "@/utils/screenshot"

let axiosInstance = newAxios.create({ headers: "" });
export default {
  components: { RoutingNumberInfo },
  name: "PaymentForm",
  props: {
    fields: {
      required: true,
    },
    form: {
      required: true,
    },
    accountTypes: {
      required: true,
    },
    accountHolderTypes: {
      required: true,
    },
  },
  data: () => ({
    apiErrors: null,
  }),
  computed: {
    ...mapGetters({
      regGroupType: "regGroupType",
    }),
  },
  methods: {
    saveAndContinue() {
      this.$emit("onOpenSaveAndContinue", this.form);
    },
    backHandler() {
      this.$emit("onBackHandler");
    },
    async submit() {
      this.form.registration_id = this.$store.getters.regTempId;
      let loader = this.$loading.show();
      let formData = this.form;
      Screenshot.captureScreenShot(Screenshot.regScreenShotSelector).then((res)=>{
        if(res){
          formData['screenshot'] = res;
        }
      }).finally(()=>{
        loader.hide();
        const url = "register/payment-info";
        this.save(url , formData);
      });
    },
    async save(url , payload){
      let loader = this.$loading.show();
      try {
        let res = await axios.post(url, payload);
        if (res.data.statusCode == 200) {
          this.$emit("onSuccess");
        }
      } catch (err) {
        if (err.response.data.statusCode == 422) {
          this.apiErrors = err.response.data.errors;
          setTimeout(() => {
            this.apiErrors = null;
          }, 5000);
        } else {
          this.$notify(err.response.data.message, "Error", "error");
        }
      } finally {
        loader.hide();
      }
    },
    customRoutingError(message) {
      this.apiErrors = {
        routing_number: [message],
      };
    },
    updateRoutingNumber() {
      let url, pattern, routingNumber;
      pattern = new RegExp("^\\d{9}$");
      routingNumber = this.form[this.fields[3].field_name];
      if (routingNumber.match(pattern)) {
        url = `${this.$endpoint.VALIDATE_ROUTING_NUMBER}${routingNumber}`;
        axiosInstance
          .get(url)
          .then((res) => {
            if (res.data.statusCode == 200) {
              this.form[this.fields[4].field_name] = res.data.data.name;
            } else if (res.data.statusCode == 404) {
              this.form[this.fields[4].field_name] = "";
              this.customRoutingError("Invalid Routing Number");
            }
          })
          .catch(() => {
            this.customRoutingError("Invalid Routing Number");
            this.form[this.fields[4].field_name] = "";
          })
          .finally(() => {
            setTimeout(() => {
              this.apiErrors = null;
            }, 5000);
          });
      } else {
        this.customRoutingError(
          "The Routing Number field must be numeric and exactly contain 9 digits"
        );
        setTimeout(() => {
          this.apiErrors = null;
        }, 5000);
      }
    },
  },
  created() {
    if (!this.$store.getters.regTempId) {
      this.backHandler();
    }
  },
  watch: {
    fields() {
      this.fields[0].value = "ach";
    },
    form() {
      this.form[this.fields[0].field_name] = "ach";
    },
  },
};
</script>
