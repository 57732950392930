<template>
  <div :class="formGroupClass">
    <validation-provider
      :name="label.toLowerCase()"
      :rules="rules"
      v-slot="validationContext"
    >
      <label>
        {{ label }}
        <span class="required-indc" v-if="field && field.required"> * </span>
      </label>
      <date-picker
        v-if="field && field.field_name"
        aria-describedby="input-feedback"
        :input-class="
          (apiErrors && apiErrors[field.field_name]) ||
          validationContext.errors[0]
            ? 'reg-field invalid-date-picker mx-input'
            : 'reg-field mx-input '
        "
        :placeholder="placeholder"
        v-model="form[field.field_name]"
        :disabled="field && field.readOnly"
        :format="dateFormat"
        value-type="format"
        :state="getValidationState(validationContext)"
      />
      <error-text
        v-if="
          (apiErrors && apiErrors[field.field_name]) ||
          validationContext.errors[0]
        "
        :errors="
          apiErrors
            ? apiErrors
            : validationContext.errors
            ? validationContext.errors
            : ''
        "
        :field="field.field_name"
      />
    </validation-provider>
  </div>
</template>
<script>
export default {
  name: "RegInputDatePicker",
  props: {
    apiErrors: {
      required: false,
    },
    form: {
      required: true,
    },
    field: {
      required: true,
    },
    label: {
      required: true,
    },
    placeholder: {
      required: false,
    },
    dateFormat: {
      required: false,
      default: "YYYY-MM-DD",
    },
    formGroupClass: {
      required: false,
      default: "col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4",
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      if (this.rules) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
  },
};
</script>
<style>
.input-required {
  color: red !important;
}

.invalid-feedback {
  display: block;
}

.invalid-date-picker {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.valid-date-picker {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.mx-input:disabled, .mx-input.disabled {
    color: #000;
    background-color: none;
    border-color: #ccc;
    cursor: not-allowed;
}

.mx-input{
  height: 40px;
}
</style>
