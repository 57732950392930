<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="form-group">
          <div class="mb-4 form-check">
            <input
              class="form-check-input"
              v-if="fields[8] && fields[8].field_name"
              type="checkbox"
              id="flexCheckDefault"
              :class="
                apiErrors && apiErrors[fields[8].field_name]
                  ? 'error-input'
                  : ''
              "
              :disabled="fields[8] && fields[8].readOnly"
              v-model="newForm[fields[8].field_name]"
              @change="checkAddress"
            />
            <label class="form-check-label" for="flexCheckDefault">
              Check this box if Employer/Group Address are the same
            </label>
            <error-text
              v-if="apiErrors && apiErrors[fields[8].field_name]"
              :errors="apiErrors"
              :field="fields[8].field_name"
            />
          </div>
        </div>

        <div class="form-group row">
          <reg-input-text
            v-if="fields[0]"
            label="First Name"
            :form="newForm"
            :field="fields[0]"
            placeholder="Enter First Name"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[0].required }"
          />
          <reg-input-text
            v-if="fields[1]"
            label="Last Name"
            :form="newForm"
            :field="fields[1]"
            placeholder="Enter Last Name"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[1].required }"
          />
        </div>

        <div class="form-group row">
          <reg-input-text
            v-if="fields[2]"
            label="Street Address 1"
            :form="newForm"
            :field="fields[2]"
            placeholder="Enter address"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[2].required }"
          />
          <reg-input-text
            v-if="fields[3]"
            label="Apt / Suite / Other"
            :form="newForm"
            :field="fields[3]"
            placeholder=""
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[3].required }"
          />
        </div>
        <div class="form-group row">
          <reg-input-text
            v-if="fields[4]"
            label="City"
            :form="newForm"
            :field="fields[4]"
            placeholder="Enter City"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[4].required }"
          />

          <reg-input-select
            v-if="fields[5]"
            label="State"
            :form="newForm"
            :field="fields[5]"
            :options="states.map((type) => type.abbrev)"
            :custom-label="(opt) => states.find((x) => x.abbrev == opt).name"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            placeholder="Choose a State"
            :api-errors="apiErrors"
            :rules="{
              required: fields && fields[5] ? fields[5].required : false,
            }"
          />
        </div>

        <div class="form-group row">
          <reg-input-text
            v-if="fields[6]"
            type="number"
            label="Zip Code"
            :form="newForm"
            :field="fields[6]"
            placeholder="Enter Zip Code"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[6].required, digits: 5 }"
          />
        </div>
        <div class="form-group row">
          <reg-input-text
            v-if="fields[9]"
            label="Email"
            type="email"
            :form="newForm"
            :field="fields[9]"
            placeholder="Enter Email"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[9].required, email: true }"
          />
          <reg-input-text
            v-if="fields[10]"
            label="Mobile Phone Number"
            type="number"
            :form="newForm"
            :field="fields[10]"
            placeholder="Enter Mobile Phone Number"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[10].required, digits: 10 }"
          />
        </div>

        <div class="form-group row">
          <reg-input-text
            v-if="fields[11]"
            label="FAX Number"
            type="number"
            :form="newForm"
            :field="fields[11]"
            placeholder="Enter FAX Number"
            :api-errors="apiErrors"
            formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
            :rules="{ required: fields[11].required, digits: 10 }"
          />
        </div>
        <div class="mb-4 form-check">
          <input
            class="form-check-input"
            v-if="fields[7] && fields[7].field_name"
            type="checkbox"
            id="flexCheckDefault"
            :class="
              apiErrors && apiErrors[fields[7].field_name] ? 'error-input' : ''
            "
            :disabled="fields[7] && fields[7].readOnly"
            v-model="newForm[fields[7].field_name]"
          />
          <label class="form-check-label" for="flexCheckDefault">
            Validate with USPS
          </label>
          <error-text
            v-if="apiErrors && apiErrors[fields[7].field_name]"
            :errors="apiErrors"
            :field="fields[7].field_name"
          />
        </div>

        <div class="action-btn split-action mt-5">
          <button class="button-black" @click="backHandler">Back</button>
          <div class="action-next">
            <button class="button-green" @click.prevent="saveAndContinue">
              Save & Continue Later
            </button>
            <button class="button-blue" type="submit">Next</button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import axios from "@/utils/regAxios";
import Screenshot from "@/utils/screenshot";

export default {
  name: "BillingForm",
  props: {
    fields: {
      required: true,
    },
    form: {
      required: true,
    },
    states: {
      required: true,
    },
  },
  data: () => ({
    apiErrors: null,
    newForm: {},
  }),
  methods: {
    fetchFormValue(index) {
      return this.form[this.fetchField(index)];
    },
    fetchField(index) {
      return this.fields[index].field_name;
    },
    async checkAddress() {
      let sameAddressField = this.fields[8].field_name;
      if (this.newForm[sameAddressField]) {
        try {
          let res = await axios({
            method: "get",
            url: "register/address-info",
            params: { registration_id: this.$store.getters.regTempId },
          });
          if (res.status == 200) {
            /** adding manullay , failed to put in object due to using method*/
            this.newForm[this.fetchField(0)] = this.newForm[this.fetchField(0)]; //first_name
            this.newForm[this.fetchField(1)] = this.newForm[this.fetchField(1)]; //last_name
            this.newForm[this.fetchField(2)] = res.data.data[0].value; //address1
            this.newForm[this.fetchField(3)] = res.data.data[1].value; //apt
            this.newForm[this.fetchField(4)] = res.data.data[2].value; //city
            this.newForm[this.fetchField(5)] = res.data.data[3].value; //state
            this.newForm[this.fetchField(6)] = res.data.data[4].value; //zip
            this.newForm[this.fetchField(9)] = this.newForm[this.fetchField(9)]; //email
            this.newForm[this.fetchField(10)] =
              this.newForm[this.fetchField(10)]; //phone_number
            this.newForm[this.fetchField(11)] =
              this.newForm[this.fetchField(11)]; //zip

            (this.newForm[this.fetchField(7)] =
              res.data.data[5].value == "true" || res.data.data[5].value == true
                ? true
                : false), //with_validation
              (this.newForm[this.fetchField(8)] =
                this.newForm[sameAddressField]);
          }
        } catch {
          this.newForm = { ...this.newForm };
        }
      } else {
        this.newForm = { ...this.newForm };
      }
    },
    setBoolean(fieldName) {
      this.form[fieldName] =
        this.form[fieldName] === "true" || this.form[fieldName] == true
          ? true
          : false;
    },
    saveAndContinue() {
      this.$emit("onOpenSaveAndContinue", this.form);
    },
    backHandler() {
      this.$emit("onBackHandler");
    },
    async submit() {
      this.newForm.registration_id = this.$store.getters.regTempId;
      let loader = this.$loading.show();
      let formData = this.newForm;
      Screenshot.captureScreenShot(Screenshot.regScreenShotSelector)
        .then((res) => {
          if (res) {
            formData["screenshot"] = res;
          }
        })
        .finally(() => {
          loader.hide();
          const url = "register/billing-contact-info";
          this.save(url, formData);
        });
    },
    async save(url, payload) {
      let loader = this.$loading.show();
      try {
        let res = await axios.post(url, payload);
        if (res.data.statusCode == 200) {
          this.$emit("onSuccess");
        }
      } catch (err) {
        if (err.response.data.statusCode == 422) {
          this.apiErrors = err.response.data.errors;
          setTimeout(() => {
            this.apiErrors = null;
          }, 5000);
        } else {
          this.$notify(err.response.data.message, "Error", "error");
        }
      } finally {
        loader.hide();
      }
    },
  },
  created() {
    if (!this.$store.getters.regTempId) {
      this.backHandler();
    }
  },
  watch: {
    form() {
      this.setBoolean(this.fields[7].field_name);
      this.setBoolean(this.fields[8].field_name);
      this.newForm = { ...this.form };
    },
  },
};
</script>
