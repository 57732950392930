<template>
  <div class="registrationWrapper">
    <div class="container">
      <div class="whiteBG-wrap">
        <div class="row registration-row">
          <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-7">
            <div class="regForm-wrap">
              <!--Title-->
              <h2>{{ title }}</h2>
              <hr />
              <!--Form-->
              <broker-form
                v-if="tab == 'broker-info'"
                :form="form"
                :fields="fields"
                @onSuccess="formHandler('group-info')"
                @onSetInfo="getInfo"
              />
              <group-info-form
                v-if="tab == 'group-info'"
                :form="form"
                :fields="fields"
                :group-types="groupTypes"
                :group-industries="groupIndustries"
                @onBackHandler="formHandler('broker-info')"
                @onSuccess="formHandler('contact-info')"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
              <contact-form
                v-if="tab == 'contact-info'"
                :form="form"
                :fields="fields"
                @onBackHandler="formHandler('group-info')"
                @onSuccess="formHandler('login-info')"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
              <login-form
                v-if="tab == 'login-info'"
                :form="form"
                :fields="fields"
                @onBackHandler="formHandler('contact-info')"
                @onSuccess="formHandler('address-info')"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
              <address-form
                v-if="tab == 'address-info'"
                :form="form"
                :fields="fields"
                :states="states"
                @onBackHandler="formHandler('login-info')"
                @onSuccess="formHandler('billing-contact-info')"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
              <billing-form
                v-if="tab == 'billing-contact-info'"
                :form="form"
                :fields="fields"
                :states="states"
                @onBackHandler="formHandler('address-info')"
                @onSuccess="
                  formHandler(
                    `${
                      regGroupType == 'employer'
                        ? 'payment-info'
                        : 'group-contribution'
                    }`,
                  )
                "
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
              <contribution-form
                v-if="tab == 'group-contribution'"
                :form="form"
                :fields="fields"
                :account-types="accountTypes"
                :account-holder-types="accountHolderTypes"
                @onSuccess="
                  formHandler(
                    `${
                      regGroupContribution
                        ? 'payment-info'
                        : 'verification-info'
                    }`,
                  )
                "
                @onBackHandler="formHandler('billing-contact-info')"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
              <payment-form
                v-if="tab == 'payment-info'"
                :form="form"
                :fields="fields"
                :account-types="accountTypes"
                :account-holder-types="accountHolderTypes"
                @onBackHandler="
                  formHandler(
                    `${
                      regGroupType == 'employer'
                        ? 'billing-contact-info'
                        : 'group-contribution'
                    }`,
                  )
                "
                @onSuccess="formHandler('verification-info')"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
              <signature-form
                v-if="tab == 'verification-info'"
                :form="form"
                :fields="fields"
                @onResetForm="resetForm"
                @onBackHandler="
                  formHandler(
                    `${
                      regGroupContribution
                        ? 'payment-info'
                        : 'group-contribution'
                    }`,
                  )
                "
                @onSuccess="completeRegistration"
                @onOpenSaveAndContinue="openSaveAndContinueModal"
              />
            </div>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5">
            <!--Sidebar-->
            <registration-sidebar :info="info" />
          </div>
        </div>
        <span class="procecss-title">Employer Registration</span>
      </div>
    </div>
    <save-and-continue
      :form.sync="saveAndContinueData"
      @onSaveAndContinueHandler="resetForm"
      @onCloseModal="closeModal"
    />
  </div>
</template>
<script>
import newAxios from 'axios'
let axiosInstance = newAxios.create({ headers: '' })
import { mapGetters } from 'vuex'
import axios from '@/utils/regAxios'
import { apiBaseUrl } from '@/resource/EndPoints/endpoints'
import RegistrationSidebar from './RegistrationSidebar.vue'
import BrokerForm from './form/BrokerForm'
import GroupInfoForm from './form/GroupInfoForm.vue'
import LoginForm from './form/LoginForm.vue'
import ContactForm from './form/ContactForm.vue'
import AddressForm from './form/AddressForm.vue'
import BillingForm from './form/BillingForm.vue'
import PaymentForm from './form/PaymentForm.vue'
import ContributionForm from './form/ContributionForm.vue'
import SignatureForm from './form/SignatureForm'
import SaveAndContinue from './utils/SaveAndContinue.vue'

export default {
  name: 'Registration',
  components: {
    BrokerForm,
    RegistrationSidebar,
    GroupInfoForm,
    LoginForm,
    ContactForm,
    AddressForm,
    BillingForm,
    PaymentForm,
    ContributionForm,
    SignatureForm,
    SaveAndContinue,
  },
  data: () => ({
    fields: [],
    form: {},
    states: [],
    groupTypes: [],
    groupIndustries: [],
    saveAndContinueData: null,
    accountTypes: [],
    accountHolderTypes: [],
    info: null,
  }),
  computed: {
    ...mapGetters({
      formUrl: 'regFormUrl',
      tab: 'regTab',
      title: 'regTitle',
      regTempId: 'regTempId',
      regGroupType: 'regGroupType',
      regGroupContribution: 'regGroupContribution',
    }),
  },
  methods: {
    signatureBackHandler() {
      //  `${regGroupType == 'employer' ? 'payment-info' : 'group-contribution' }`
      console.log('sa', this.regGroupType, this.re)
    },
    async getInfo(code = null) {
      let url
      url = `${apiBaseUrl}/api/v2/rep-email-phone`
      if (code) {
        url = url + `?rep_code=${code}`
      } else if (this.$route.params.repCode) {
        let code = this.$route.params.repCode
        url = url + `?rep_code=${code}`
      }
      await axiosInstance
        .get(url)
        .then((res) => {
          const { phone, email } = { ...res.data.data }
          this.info = {
            phone,
            email,
          }
        })
        .catch(() => {
          this.info = null
        })
    },
    getAccountTypes() {
      axiosInstance
        .get('/account-types')
        .then((res) => {
          this.accountTypes = res.data.data
        })
        .catch(() => {
          this.accountTypes = []
        })
    },
    getAccountHolderTypes() {
      axiosInstance
        .get('/account-holder-types')
        .then((res) => {
          this.accountHolderTypes = res.data.data
        })
        .catch(() => {
          this.accountHolderTypes = []
        })
    },
    completeRegistration() {
      this.$store.commit('setRegFormUrl', null)
      this.$store.commit('setRegTitle', null)
      this.$store.commit('setRegTab', null)
      this.$store.commit('setRegTempId', null)
      this.saveAndContinueData = null
      this.$router.push({
        name: 'RegisterSuccess',
        params: { canAccess: true },
      })
    },
    closeModal() {
      this.saveAndContinueData = null
      this.form.savecont = false
      this.$bvModal.hide('saveAndContinueModal')
    },
    openSaveAndContinueModal(data) {
      this.saveAndContinueData = null
      if (data) {
        this.saveAndContinueData = data
        this.$bvModal.show('saveAndContinueModal')
      }
    },
    resetForm() {
      this.$store.commit('setRegFormUrl', null)
      this.$store.commit('setRegTitle', null)
      this.$store.commit('setRegTab', null)
      this.$store.commit('setRegTempId', null)
      this.saveAndContinueData = null
      this.setData()
      this.getFormField()
      this.getInfo()
    },
    setData(
      tab = 'broker-info',
      title = 'Rep Information',
      formUrl = 'representative-info',
    ) {
      let url = `register/${formUrl}`
      this.$store.commit('setRegFormUrl', url)
      this.$store.commit('setRegTitle', title)
      this.$store.commit('setRegTab', tab)
    },
    formHandler(type) {
      switch (type) {
        case 'broker-info':
          this.setData(
            'broker-info',
            'Rep Information',
            'representative-info',
          )
          this.getFormField()
          break
        case 'group-info':
          this.setData('group-info', 'Group Information', 'group-info')
          this.getFormField()
          break
        case 'contact-info':
          this.setData(
            'contact-info',
            'Group Contact Information',
            'contact-info',
          )
          this.getFormField()
          break
        case 'login-info':
          this.setData(
            'login-info',
            'Group/Admin Login Information',
            'login-info',
          )
          this.getFormField()
          break
        case 'address-info':
          this.setData(
            'address-info',
            'Group Address Information',
            'address-info',
          )
          this.getFormField()
          break
        case 'billing-contact-info':
          this.setData(
            'billing-contact-info',
            'Billing Address & Contact Information',
            'billing-contact-info',
          )
          this.getFormField()
          break
        case 'payment-info':
          this.setData('payment-info', 'Payment Information', 'payment-info')
          this.getFormField()
          break
        case 'group-contribution':
          this.setData(
            'group-contribution',
            'Group Contribution',
            'group-contribution',
          )
          this.getFormField()
          break
        case 'verification-info':
          this.setData(
            'verification-info',
            'Signature & Verification',
            'verification-info',
          )
          this.getFormField()
          break
        default:
          this.setData(
            'broker-info',
            'Rep Information',
            'representative-info',
          )
          this.getFormField()
      }
    },
    async getGroupIndustries() {
      await axiosInstance
        .get('/group-industries')
        .then((res) => {
          this.groupIndustries = res.data.data
        })
        .catch(() => {
          this.groupIndustries = []
        })
    },
    async getGroupTypes() {
      await axiosInstance
        .get('/group-types')
        .then((res) => {
          this.groupTypes = res.data.data
        })
        .catch(() => {
          this.groupTypes = []
        })
    },
    getStates() {
      axiosInstance
        .get('/get-states')
        .then((res) => {
          this.states = res.data.data
        })
        .catch(() => {
          this.states = []
        })
    },
    getFormField() {
      let url = this.$store.getters.regFormUrl
      let regTempId = this.$store.getters.regTempId
      let regTempData = []
      if (regTempId) {
        regTempData = {
          registration_id: regTempId,
        }
      }
      let loader = this.$loading.show()
      axios({
        method: 'get',
        url: url,
        params: regTempData,
      })
        .then((res) => {
          this.fields = res.data.data
          this.form = null
          this.fields.map((data) => {
            this.form = { ...this.form, [data.field_name]: data.value }
          })
        })
        .catch(() => {
          this.form = null
        })
        .finally(() => {
          loader.hide()
        })
    },
  },
  /**
   * usercase repCode and rep_code : using repCode and rep_code as param and query respectively
   * repCode is used through registration route, will autopopulate in broker
   * rep_code is used through continue registration ,only auto populate if registration id matches
   */
  created() {
    if (!this.$store.getters.regTempId) {
      this.setData()
    }
    this.$nextTick(() => {
      this.getFormField()
    })
    this.getStates()
    this.getGroupTypes()
    this.getGroupIndustries()
    this.getAccountTypes()
    this.getAccountHolderTypes()
    if (this.$route.params.repCode) {
      this.$store.commit('setRegTempId', null)
      this.setData()
    }
    if (this.$route.query.rep_code) {
      this.getInfo(this.$route.query.rep_code)
      /**
       * removing string paramater from url
       */
      this.$router.replace('/registration')
    } else {
      this.getInfo()
    }
  },
  watch: {
    form() {
      if (
        this.$route.params.repCode &&
        this.$store.getters.regTab == 'broker-info' && !this.$route.params.groupCensusId
      ) {
        let agentField = this.fields[0].field_name
        this.form[agentField] = this.$route.params.repCode //agent_ga
        this.$store.commit('setGroupCensusId','')
        this.form['group_census_id'] = ''
      } else if (  this.$route.params.repCode &&
          this.$store.getters.regTab == 'broker-info' && this.$route.params.groupCensusId ) {
        let agentField = this.fields[0].field_name
        this.form[agentField] = this.$route.params.repCode //agent_ga
        this.$store.commit('setGroupCensusId', this.$route.params.groupCensusId || '')
        this.form['group_census_id'] = this.$route.params.groupCensusId || ''
     }
    }
  },
}
</script>
<style src="@/assets/css/group-registration.css"></style>
