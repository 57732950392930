<template>
  <b-modal
    id="saveAndContinueModal"
    centered
    size="md"
    hide-footer
    no-close-on-backdrop
  >
    <template #modal-header>
      <div class="save-and-continue-header">
        <h4>Save And Continue Later</h4>
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid ref="saveAndContinueContainer">
      <div class="save-and-continue-modal-content">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(submit)"
            ref="saveAndContinueModalContainer"
          >
            <div class="form-group row">
              <input-text
                :rules="{ required: true, email: true }"
                labelFor="savecont_email"
                labelName="Email"
                :vmodel.sync="email"
                formGroupClass="col-xxl-12 col-xl-12 col-lg-12 mb-6"
                :api-errors="apiErrors"
              />
            </div>
            <div class="form-group text-center mb-3 mt-3">
              <button
                type="submit"
                class="btn univ-btn btn-secondary"
                :disabled="isSubmit"
              >
                {{ isSubmit ? "Saving..." : "Save" }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import axios from "@/utils/regAxios";
export default {
  name: "SaveAndContinue",
  data: () => ({
    isSubmit: false,
    email: "",
    apiErrors: null,
  }),
  props: {
    form: {
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.email = null;
      this.$emit("onCloseModal");
    },
    async submit() {
      let loader = this.$loading.show({
        container: this.$refs.saveAndContinueContainer,
      });
      this.form.registration_id = this.$store.getters.regTempId;
      this.form.savecont = true;
      const url = this.$store.getters.regFormUrl;
      let formData = this.form;
      formData.savecont_email = this.email;
      this.isSubmit = true;
      try {
        let res = await axios.post(url, formData);
        if (res.data.statusCode == 200) {
          this.$notify(res.data.message);
          this.email = null;
          this.$bvModal.hide("saveAndContinueModal");
          this.$emit("onSaveAndContinueHandler");
        }
      } catch (err) {
        if (err.response.data.statusCode == 422) {
          this.apiErrors = err.response.data.errors;
          setTimeout(() => {
            this.apiErrors = null;
          }, 5000);
        } else {
          this.$notify(err.response.data.message, "Error", "error");
        }
      } finally {
        this.isSubmit = false;
        loader.hide();
      }
    },
  },
};
</script>
<style scoped>
</style>