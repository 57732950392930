<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="form-group">
        <reg-input-text
          v-if="fields[0]"
          label="Email Address"
          type="email"
          :form="form"
          :field="fields[0]"
          placeholder="Enter Email Address"
          :api-errors="apiErrors"
          formGroupClass="mb-4"
          :rules="{ required: fields[0].required, email: true }"
        />
      </div>
      <div class="form-group row">
        <reg-input-text
          v-if="fields[1]"
          label="Password"
          type="password"
          :form="form"
          :field="fields[1]"
          placeholder="Password"
          :api-errors="apiErrors"
          formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
          :rules="{ required: fields[1].required }"
        >
          <template v-slot:appendIcon>
            <eye-button
              id="password"
              class-name="toggle-password-icon"
              :hide-on-default="true"
              type="text"
            />
          </template>
          <template v-slot:shortMessage>
            <span
              class="short-msg"
              v-if="!(apiErrors && apiErrors[fields[1].field_name])"
            >
              At least 8 Characters: One Uppercase Letter, One Lowercase Letter,
              One Number and One Special Character
            </span>
          </template>
        </reg-input-text>

        <reg-input-text
          v-if="fields[2]"
          label="Confirm Password"
          type="password"
          :form="form"
          :field="fields[2]"
          placeholder="Confirm Password"
          :api-errors="apiErrors"
          formGroupClass="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-4"
          :rules="{ required: fields[2].required }"
        >
          <template v-slot:appendIcon>
            <eye-button
              id="password_confirmation"
              class-name="toggle-password-icon"
              :hide-on-default="true"
              type="text"
            />
          </template>
        </reg-input-text>
      </div>
      <div class="action-btn split-action mt-5">
        <button class="button-black" @click="backHandler">Back</button>
        <div class="action-next">
          <button class="button-green" @click.prevent="saveAndContinue">
            Save & Continue Later
          </button>
          <button class="button-blue" type="submit">Next</button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import axios from "@/utils/regAxios";
import Screenshot from "@/utils/screenshot"

export default {
  name: "LoginForm",
  props: {
    fields: {
      required: true,
    },
    form: {
      required: true,
    },
  },
  data: () => ({
    apiErrors: null,
  }),
  methods: {
    saveAndContinue() {
      this.$emit("onOpenSaveAndContinue", this.form);
    },
    backHandler() {
      this.$emit("onBackHandler");
    },
    async submit() {
      this.form.registration_id = this.$store.getters.regTempId;
      let loader = this.$loading.show();
      let formData = this.form;
      Screenshot.captureScreenShot(Screenshot.regScreenShotSelector).then((res)=>{
        if(res){
          formData['screenshot'] = res;
        }
      }).finally(()=>{
        loader.hide();
        const url = "register/login-info";
        this.save(url , formData);
      });
    },
    async save(url , payload){
      let loader = this.$loading.show();
       try {
        let res = await axios.post(url, payload);
        if (res.data.statusCode == 200) {
          this.$emit("onSuccess");
        }
      } catch (err) {
        if (err.response.data.statusCode == 422) {
          this.apiErrors = err.response.data.errors;
          setTimeout(() => {
            this.apiErrors = null;
          }, 5000);
        } else {
          this.$notify(err.response.data.message, "Error", "error");
        }
      } finally {
        loader.hide();
      }
    }
  },
  created() {
    if (!this.$store.getters.regTempId) {
      this.backHandler();
    }
  },
};
</script>
