var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.formGroupClass},[_c('validation-provider',{attrs:{"name":_vm.label.toLowerCase(),"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('label',[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.field && _vm.field.required)?_c('span',{staticClass:"required-indc"},[_vm._v(" * ")]):_vm._e()]),(_vm.field && _vm.field.field_name)?_c('date-picker',{attrs:{"aria-describedby":"input-feedback","input-class":(_vm.apiErrors && _vm.apiErrors[_vm.field.field_name]) ||
        validationContext.errors[0]
          ? 'reg-field invalid-date-picker mx-input'
          : 'reg-field mx-input ',"placeholder":_vm.placeholder,"disabled":_vm.field && _vm.field.readOnly,"format":_vm.dateFormat,"value-type":"format","state":_vm.getValidationState(validationContext)},model:{value:(_vm.form[_vm.field.field_name]),callback:function ($$v) {_vm.$set(_vm.form, _vm.field.field_name, $$v)},expression:"form[field.field_name]"}}):_vm._e(),(
        (_vm.apiErrors && _vm.apiErrors[_vm.field.field_name]) ||
        validationContext.errors[0]
      )?_c('error-text',{attrs:{"errors":_vm.apiErrors
          ? _vm.apiErrors
          : validationContext.errors
          ? validationContext.errors
          : '',"field":_vm.field.field_name}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }